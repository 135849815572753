import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'

const app = initializeApp({
    apiKey: "AIzaSyB7dGttto0LbQBxYQWEvEirZ3qvTeRD80k",
    authDomain: "portfolio-309211.firebaseapp.com",
    databaseURL: "https://portfolio-309211-default-rtdb.firebaseio.com",
    projectId: "portfolio-309211",
    storageBucket: "portfolio-309211.appspot.com",
    messagingSenderId: "209064072783",
    appId: "1:209064072783:web:b5d773247874873434bc3a"
});

const database = getDatabase(app)   

export default database