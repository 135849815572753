import React, { useEffect, useRef } from "react";
import "./App.css";
import Header from "./Header/Header";
import Homepage from "./Homepage/Homepage";
import Projects from "./Projects/Projects";
import Contact from "./Contact/Contact";

import { BrowserRouter as Router } from "react-router-dom";

import { useStateValue } from "./StateProvider";

const App = () => {
  const [{ theme }, dispatch] = useStateValue();

  //References
  const homePageRef = useRef();
  const projectsRef = useRef();
  const contactRef = useRef();

  useEffect(() => {
    let dark;

    if (localStorage.getItem("theme-mode") === null) {
      dark = true;
      localStorage.setItem("theme-mode", dark);
    }

    if (localStorage.getItem("theme-mode") === "true") {
      document.documentElement.setAttribute("data-theme", "dark");
      dark = true;
    } else {
      document.documentElement.setAttribute("data-theme", "light");
      dark = false;
    }

    dispatch({
      type: "CHANGE_THEME",
      item: dark,
    });
  }, [theme, dispatch]);

  return (
    <div className="app">
      <Router>
        <>
          <Header
            homePageRef={homePageRef}
            projectsRef={projectsRef}
            contactRef={contactRef}
          />
          <Homepage pageReference={homePageRef} />
          <Projects pageReference={projectsRef} />
          <Contact pageReference={contactRef} />
        </>
      </Router>
    </div>
  );
};

export default App;
