/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./Homepage.css";

import { useStateValue } from '../StateProvider'
import { ref, set, get, push } from 'firebase/database'
import database from '../firebase'

const Homepage = ({ pageReference }) => {
  const [{ theme }] = useStateValue();
  const dbRef = ref(database, 'counter')

  const technologies = ['JavaScript', 'Python', 'Flutter', 'NodeJS', 'React', 'Redux', 'Docker', 'REST', 'MongoDB', 'NoSQL', 'Django', 'MySQL', 'HTML5', 'CSS', 'SASS', 'Tailwind', 'Bootstrap', 'GraphQL']

  useEffect(() => {
    const data = push(dbRef).key;
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(ipData => {
        set(ref(database, 'counter/' + data), { count: 1, date: new Date().toISOString(), ip: ipData.ip });
      })
      .catch(error => {
        console.error("Error fetching IP address:", error);
      });

    const ref2 = ref(database, 'total_count');

    get(ref2).then(snapshot => {
      if (snapshot.exists()) {
        set(ref2, { count: snapshot.val().count + 1 });
      } else {
        set(ref2, { count: 1 });
      }
    }).catch(error => {
      console.error("Error getting total_count:", error);
    });

  }, []);

  return (
    <div id="homepage" ref={pageReference}>
      <div className="homepage_data">
        <div className="homepage_dataAboutMe">
          <div className="personal_infos">
            <div className="personal_infoSayHi">
              <span className={`squeez squeez_big animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>H</span>
              <span className={`squeez squeez_big animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>i</span>
              <span className={`squeez squeez_big animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>,</span>
            </div>
            <div className="personal_infoName">
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>I</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>'</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>m&nbsp;</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>S</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>a</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>l</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>i</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>h</span>
              {/* <h1>I'm Salih,</h1> */}
            </div>
            <div className="personal_infoJob">
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>w</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>e</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>b&nbsp;</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>d</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>e</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>v</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>e</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>l</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>o</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>p</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>e</span>
              <span className={`squeez squeez_small animated rubber ${theme ? 'white_text' : 'black_text'}`} style={{ opacity: 1 }}>r</span>
            </div>
            <div className="personal_infoSkill">
              <h3>Fullstack Developer</h3>
            </div>
          </div>
        </div>
        <div className="homepage_dataTechs">
          <div id="tagcloud">
            <ul>
              {technologies.map(tech => (
                <li>
                  <p>{tech}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
